
import { mapStores } from "pinia"

import { AnalyticsEventPageId } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

import SitePlannerViewer from "@/components/sitePlanner/SitePlannerViewer"

export default {
  name: "SitePlannerProjectList",
  meta: {
    pageId: AnalyticsEventPageId.sitePlanner,
  },
  components: { SitePlannerViewer },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
  },
}
